<template>
  <div>
    <div
      class="loaded"
      v-if="!busy && !error"
    >
      <template v-if="isMobile">
        <Navbar
          :cafe="cafe"
          :target="{ preorder: true }"
          :show_status="true"
          :choosing="true"
          :showArrowBack="isFromMarketplace"
          @go-back="onBackArrowPressed"
        />
        <ChooseModeUI
          :cafe="cafe"
          @select="onSelect"
        />

        <!-- <div v-thematic class="mode-bg"></div> -->
        <!-- <PreorderMode
          :modes="cafe.modes"
          :cafe_id="cafe._id"
          @select="onSelect"
          @cancel="backToMarketplace"
        /> -->
      </template>
      <template v-else>
        <NavbarDesktop
          :cafe="cafe"
          variant="choose_mode"
          mode=""
          :hideArrowBack="!isFromMarketplace"
        />
        <CafePageDesktop
          :cafe="cafe"
          :from_map="false"
        />
      </template>
    </div>

    <div v-if="busy && !error">
      <OrderLoader />
    </div>
    <LoadError
      :error="error"
      v-if="error"
    />
  </div>
</template>

<script>
import PreorderMode from '@/components/PreorderMode.vue'
import Navbar from '@/components/Navbar.vue'
import NavbarDesktop from '@/components/desktop/NavbarDesktop.vue'
import OrderLoader from '@/components/OrderLoader.vue'

import CafePageDesktop from '@/components/desktop/CafePageDesktop.vue'

import MarketplaceService from '@/services/marketplace'

import PreorderAPI from '@/api/preorder'

import ChooseModeUI from '@/components/ChooseModeUI.vue'

import LoadError from '@/components/LoadError.vue'

import ThemeService from '@/services/theme/service'

export default {
  data() {
    return {
      busy: true,
      error: null,
      cafe: {},
      addedToFav: false,
      schedule: {
        activeDay: 1,
        days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд']
      }
    }
  },
  methods: {
    onSelect(is_delivery) {
      this.$router.replace({
        name: is_delivery ? 'delivery' : 'preorder',
        params: {
          code: this.$route.params.code
        }
      })
    },
    fetchData() {
      PreorderAPI.lookup(this.$route.params.code, MarketplaceService.get().flag)
        .then(data => {
          if (data.cafe.is_chain_link) {
            this.$router.replace({
              name: 'chain',
              params: {
                chain_id: this.$route.params.code
              }
            })
            return
          }

          //TODO: remove when menu preview will be available in new UI
          if (
            data.cafe.ui_settings &&
            data.cafe.ui_settings.use_inside_superapp &&
            (!data.cafe.has_preview || data.cafe.modes.preorder || data.cafe.modes.delivery)
          ) {
            window.location.href = `https://new.qrwaiter.com.ua/${this.$route.params.code}`
            return
          }

          this.cafe = data.cafe

          if (!MarketplaceService.get().flag) {
            window.document.title = this.cafe.name || 'QR'
          }

          if (this.cafe._id == 126) {
            this.$i18n.locale = 'rus'
          }

          if (this.cafe.ui_settings && this.cafe.ui_settings.theme_override) {
            ThemeService.setGlobalOverride(this.cafe.ui_settings.theme_override)
          }

          if (this.cafe.ui_settings && this.cafe.ui_settings.locale_override) {
            this.$i18n.locale = this.cafe.ui_settings.locale_override
          }

          this.$store.dispatch('currency/fetch', this.cafe.currency_id)
          this.checkInstantMode()
        })
        .catch(error => {
          this.error = error
        })
    },
    checkInstantMode() {
      let active_modes = 0

      if (this.cafe.modes.preorder) active_modes++
      if (this.cafe.modes.delivery) active_modes++
      if (this.cafe.modes.reserve) active_modes++

      if (this.$route.query.force_mode) {
        const target_mode = this.$route.query.force_mode

        if (target_mode == 'preorder' && this.cafe.modes.preorder) {
          this.onSelect(false)
          return
        }

        if (target_mode == 'delivery' && this.cafe.modes.delivery) {
          this.onSelect(true)
          return
        }

        if (target_mode == 'reserve' && this.cafe.modes.reserve) {
          this.$router.push({
            name: 'reserve',
            params: {
              code: this.$route.params.code
            }
          })
          return
        }
      }

      if (this.cafe._id == 300 || this.cafe._id == 329) {
        //khvanchakra
        this.onSelect(false)
        return
      }

      if (this.cafe._id == 294) {
        this.onSelect(true)
        return
      }

      if (this.cafe.ui_settings && this.cafe.ui_settings.skip_cafe_page && active_modes <= 1) {
        if (this.cafe.modes.preorder) {
          this.onSelect(false)
        } else if (this.cafe.modes.delivery) {
          this.onSelect(true)
        }
      }

      if (this.cafe._id == 336) {
        //cargo kitchen
        window.localStorage.setItem(`last_referral_id`, `${this.$route.params.code}:l90xxfUx`)
        this.$store.commit('setAppBannerShown', false)
        this.onSelect(false)
        return
      }

      this.busy = false

      if (active_modes > 1) {
        return
      }

      // if (this.$route.query.returning) {
      //   this.$router.push("/marketplace");
      //   return;
      // }

      // if (this.cafe.modes.preorder) {
      //   this.onSelect(false);
      // } else if (this.cafe.modes.delivery) {
      //   this.onSelect(true);
      // } else {
      //   this.$router.push({
      //     name: "reserve",
      //     params: {
      //       code: this.$route.params.code,
      //     },
      //   });
      // }
    },
    backToMarketplace() {
      this.$router.push({
        name: 'marketplace',
        query: {
          skip: true,
          mode: this.$route.name
        }
      })
    },
    onBackArrowPressed() {
      if (this.isFromMarketplace) {
        this.$router.push({
          name: 'marketplace',
          query: this.$route.query
        })
      }
    },
    changeWorkDay(day) {
      this.schedule.activeDay = day
    }
  },
  computed: {
    individualDelivery() {
      if (!this.cafe) return null
      const id = `${this.cafe._id}`
      if (id == '46') return 'tenshi'
      if (id == '39') return 'teritoria'

      return null
    },
    isFromMarketplace() {
      return MarketplaceService.get().flag
    }
  },
  mounted() {
    if (this.$route.params.code != 'uzhfruit_') {
      window.location.href = `https://new.qrwaiter.com.ua/${this.$route.params.code}`
      return
    }

    if (!MarketplaceService.isValid(this.$route.params.code)) {
      console.log(`not valid`)
      MarketplaceService.set(this.$route.params.code, false)
      // this.$router.push("/marketplace");
      // return;
    }

    this.fetchData()
    window.document.body.style.backgroundColor = '#ededed'
  },
  beforeDestroy() {
    this.$store.commit('setAppBannerShown', false)
  },
  components: {
    PreorderMode,
    Navbar,
    OrderLoader,
    LoadError,
    CafePageDesktop,
    NavbarDesktop,
    ChooseModeUI
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.loaded {
  position: absolute;
  width: 100%;
  background: #ededed;
}

.loaded.dark {
  background: #000;
}
</style>